<template>
    <div v-if="detailLoad">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card p-4">
                    <div>
                        <b-row>
                            <b-col lg="10" md="10" sm="12">
                                <h4>
                                    <span class="text-capitalize">
                                        {{$t("title.priceDetail")}}
                                    </span>
                                </h4>
                                <h6>
                                    <span class="text-secondary">
                                        {{$global.utcDateToLocalDate(detailLoad.created_at) }}
                                    </span>
                                </h6>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" class="text-right">
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleCancelClick"
                                          v-b-tooltip.hover :title="$t('button.title.back')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                </b-button>
                            </b-col>
                        </b-row>
                        <div class="mt-3">
                            <div class="box border rounded p-4">
                                <div class="clearfix">
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.supplier')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.supplier ? detailLoad.supplier.name : ""}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.route')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.route ? detailLoad.route.name : ""}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.brand')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.brand ? detailLoad.brand.title : ""}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.models')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{ (detailLoad.models ? _.map(detailLoad.models, (item) => `${item.title}-${item.version_code}`).join(', ') : '') }}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.markets')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{ (detailLoad.markets ? _.map(detailLoad.markets, (item) =>
                                                item.name).join(', ') : '') }}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.logisticTypes')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.logistic_type ? detailLoad.logistic_type.title : ""}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.loadingFactors')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.loading_factors}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.leadTimePickup')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.lead_time_pickup}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.leadTimeTransport')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.lead_time_transport}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.inboundDelay')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.inbound_delay}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.outboundDelay')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.outbound_delay}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.commitmentOnTruckLoadsPerWeek')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.commitment_on_truck_loads_per_week}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.flexibility')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.flexibility}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row v-show="detailLoad.comments">
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.comments')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.comments}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.unitPrice')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="pull-right">
                                                <div class="input-group input-group-sm"
                                                     v-if="detailLoad.price_periods" size="sm"
                                                     v-for="(pp, index) in detailLoad.price_periods">
                                                    <span v-if="handlePricePeriodShow(pp)" class="mr-1">{{pp.price_unit}}</span>
                                                    <span v-if="handlePricePeriodShow(pp)">
                                                        {{(_.find(dropdowns.currencies, {id: pp.currency}) ? _.find(dropdowns.currencies, {id: pp.currency}).label : '')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.priceFTL')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="pull-right">
                                                <div class="input-group input-group-sm"
                                                     v-if="detailLoad.price_periods" size="sm"
                                                     v-for="(pp, index) in detailLoad.price_periods">
                                                    <span v-if="handlePricePeriodShow(pp)"
                                                          class="mr-1">{{pp.price_ftl}}</span>
                                                    <span v-if="handlePricePeriodShow(pp)">
                                                        {{(_.find(dropdowns.currencies, {id: pp.currency}) ? _.find(dropdowns.currencies, {id: pp.currency}).label : '')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.attachments')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                <a v-if="detailLoad.price_documents.length > 0"
                                                   v-b-modal.files-attachments
                                                   @click="getAttachmentItem(detailLoad.price_documents)">
                                                    <i class="fe fe-paperclip"></i>Attachments</a>
                                                <a v-else href="javascript:;">No Attachment</a>
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <!--  attachment modal-->
        <b-modal id="files-attachments" ok-only :title="$t('title.attachments')" ok-title="Cancel">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{{$t('column.attachment')}}</th>
                        <th>{{$t('column.download')}}</th>
                        <th>{{$t('column.createdAt')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(file,index) in selectedAttachment">
                        <td>{{index+1}}</td>
                        <td>{{file.title}}.{{file.document.extension}}</td>
                        <td>
                            <a :title="$t('button.download')"
                               v-b-tooltip.hover variant="outline-primary"
                               class="mb-1"
                               v-if="file.document && file.document.download_url"
                               :disabled="!(file.document && file.document.download_url)"
                               :href="(file.document ? file.document.download_url : '')"
                               target="_blank">
                                <i class="fe fe-download"></i>
                            </a>
                        </td>
                        <td>
                            {{file.document.created_at ? $global.utcDateToLocalDate(file.document.created_at): ''}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {request} from "../../util/Request";
    import moment from 'moment-timezone'

    export default {
        props: ['handleCloseOperation'],
        data() {
            return {
                detailLoad: null,
                selectedAttachment: [],
                dropdowns: {
                    currencies: [],
                }
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getDetail(this.$route.query.oToken)
            } else {
                this.serverError()
            }
            this.getCurrencies()
        },
        methods: {
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            handlePricePeriodShow(price) {
                return moment(price.start_date) <= moment().toDate() && moment().toDate() <= moment(price.end_date)
            },
            getAttachmentItem(item) {
                this.selectedAttachment = item
            },
            async getDetail(id) {
                await request({
                    url: `/prices/detail/${id}`,
                })
                    .then((response) => {
                        const {data} = response
                        this.detailLoad = data
                    })
                    .catch((error) => {

                    })
            },
            handleCancelClick() {
                this.handleCloseOperation()
            }
        }
    }
</script>
