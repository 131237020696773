import Error from '../../util/Error'

const PRICE_MODEL_EXCEPTION_STATE = {
    token: null,
    id: null,
    model_id: null,
    price: null,
    currency: null,
    error: false,
};

export default {
    data() {
        return {
            price_model_exception: {...PRICE_MODEL_EXCEPTION_STATE},
            price_model_exceptions: [],
        }
    },
    methods: {
        handleAddUpdatePriceModelExceptionClick() {
            this.price_model_exception.error = false;
            this.formErrors = new Error({})
            let errors = {};


            if (!this.price_model_exception.price) {
                this.price_model_exception.error = true;
                errors.pme_price = [this.$t('validation.required')];
            }

            if (!this.price_model_exception.currency) {
                this.price_model_exception.error = true;
                errors.pme_currency = [this.$t('validation.required')];
            }

            if (!this.price_model_exception.model_id) {
                this.price_model_exception.error = true;
                errors.pme_model_id = [this.$t('validation.required')];
            }


            _.map(this.price_model_exceptions, (period) => {
                if (period.token !== this.price_model_exception.token) {
                    if (period.model_id === this.price_model_exception.model_id) {
                        errors.pme_model_id = [this.$t('validation.duplicate')];
                        this.price_model_exception.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });


            this.formErrors = new Error(errors)
            if (this.price_model_exception.error) return false;

            const entity = this.price_model_exception

            let index = -1;
            if (entity.token)
                index = _.findIndex(this.price_model_exceptions, {token: entity.token});

            if (_.isArray(this.price_model_exception.model_id) && this.price_model_exception.model_id.length > 0) {
                _.map(this.price_model_exception.model_id, (model_id) => {
                    const indexByModel = _.findIndex(this.price_model_exceptions, {model_id: model_id})
                    if (this.price_model_exceptions[indexByModel] !== undefined) {
                        this.price_model_exceptions[indexByModel] = {...entity, model_id: model_id};
                    } else {
                        this.price_model_exceptions.push({
                            ...this.price_model_exception,
                            model_id: model_id,
                            token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                        })
                    }
                })
            }

            this.price_model_exception = {
                ...PRICE_MODEL_EXCEPTION_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleDeletePriceModelExceptionClick(token) {
            const index = _.findIndex(this.price_model_exceptions, {token: token})
            if (this.price_model_exceptions[index] !== undefined) {
                this.price_model_exceptions.splice(index, 1);
            }
        },
        // handleEditPriceModelExceptionClick(token) {
        //     const index = _.findIndex(this.price_model_exceptions, {token: token})
        //     if (this.price_model_exceptions[index] !== undefined) {
        //         this.price_model_exception = {...this.price_model_exceptions[index]};
        //     }
        // },
        resetPriceModelException() {
            this.price_model_exception = {...PRICE_PERIOD_STATE}
            this.price_model_exception.error = false;
        },
    }
}
