<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{$t('title.prices')}}
                                        <span v-if="pagination.total">({{pagination.total}})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button @click="setOperation('add')" variant="info" size="sm"
                                                      :title="$t('button.title.addNewItem')"
                                                      v-if="$global.hasPermission('pricesstore')" v-b-tooltip.hover>
                                                <i class="fe fe-plus"></i> {{$t('button.addNew')}}
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('pricesview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="prices-table">
                    <div class="text-right font-weight-bold text-gray-4">
                        <span>
                            <i class="text-danger fa fa-dot-circle-o"></i> {{$t('title.priceExpired')}}
                        </span>
                        <span>
                            <i class="text-warning fa fa-dot-circle-o"></i> {{$t('title.priceExpiresSoon')}}
                        </span>
                    </div>
                    <b-table hover responsive
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             ref="orderTable"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(id)="{ detailsShowing, item, index, rowSelected}">
                            <div class="position-relative">
                                <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                </template>
                                <a @click="toggleDetails(item)" class="d-inline in-center">
                                    <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                </a>
                            </div>
                        </template>
                        <template v-slot:cell(supplier_id)="record">
                            <span
                                :class="[(doesPricePeriodExpired(record.item.price_periods) <= 0) ? 'text-danger' : (doesPricePeriodExpireInOneMonth(record.item.price_periods) ? 'text-warning' : '')]">
                                {{ (record.item.supplier ? record.item.supplier.name : '') }}
                            </span>
                        </template>
                        <template v-slot:cell(route_id)="record">
                            {{ (record.item.route ? record.item.route.name : '') }}
                        </template>
                        <template v-slot:cell(brands)="record">
                            {{ _.uniqBy(record.item.brands, 'title').map(item => item.title).join(', ') }}
                        </template>
                        <template v-slot:cell(models)="record">
                            {{ _.uniqBy(record.item.models, 'title').map(item => item.title).join(', ') }}
                        </template>
                        <template v-slot:cell(price_unit)="record">
                            <div class="input-group input-group-sm mr-5 mt-0" v-if="record.item.price_periods" size="sm"
                                 v-for="(pp, index) in record.item.price_periods">
                                <span v-if="handlePricePeriodShow(pp)" class="mr-1">{{pp.price_unit}}</span>
                                <span v-if="handlePricePeriodShow(pp)">
                                    {{(_.find(dropdowns.currencies, {id: pp.currency}) ? _.find(dropdowns.currencies, {id: pp.currency}).label : '')}}
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell(price_ftl)="record">
                            <div class="input-group input-group-sm mr-5 mt-0" v-if="record.item.price_periods" size="sm"
                                 v-for="(pp, index) in record.item.price_periods">
                                <span v-if="handlePricePeriodShow(pp)" class="mr-1">{{pp.price_ftl}}</span>
                                <span v-if="handlePricePeriodShow(pp)">
                                    {{(_.find(dropdowns.currencies, {id: pp.currency}) ? _.find(dropdowns.currencies, {id: pp.currency}).label : '')}}
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell(price_spl)="record">
                            <div class="input-group input-group-sm mr-5 mt-0" v-if="record.item.price_periods" size="sm"
                                 v-for="(pp, index) in record.item.price_periods">
                                <span v-if="handlePricePeriodShow(pp)" class="mr-1">{{pp.price_spl}}</span>
                                <span v-if="handlePricePeriodShow(pp)">
                                    {{(_.find(dropdowns.currencies, {id: pp.currency}) ? _.find(dropdowns.currencies, {id: pp.currency}).label : '')}}
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('edit', record.item.id)"
                               :title="$t('button.title.editItem')" v-if="$global.hasPermission('pricesupdate')"
                               v-b-tooltip.hover>
                                <i class="fe fe-edit"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="$global.hasPermission('pricesdestroy')">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class=" ml-1"
                                   :title="$t('button.title.deleteItem')"
                                   v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>
                            <a @click="setOperation('detail', record.item.id)"
                               :title="$t('button.title.detailItem')" class=" ml-1"
                               v-if="$global.hasPermission('pricesview')"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                        </template>
                        <template v-slot:row-details="{ item }">
                            <b-card>
                                <b-list-group flush>
                                    <b-list-group-item>
                                        <strong>{{$t('column.flexibility')}}</strong>: {{item.flexibility}}
                                    </b-list-group-item>
                                    <b-list-group-item>
                                        <strong>{{$t('column.commitmentOnTruckLoadsPerWeek')}}</strong>:
                                        {{item.commitment_on_truck_loads_per_week}}
                                    </b-list-group-item>
                                    <b-list-group-item>
                                        <strong>{{$t('column.outboundDelay')}}</strong>: {{item.outbound_delay}}
                                    </b-list-group-item>
                                    <b-list-group-item>
                                        <strong>{{$t('column.inboundDelay')}}</strong>: {{item.inbound_delay}}
                                    </b-list-group-item>
                                </b-list-group>
                            </b-card>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.prices-table-->
                <div class="prices-operation">
                    <a-drawer
                        placement="right"
                        :width="'950px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationClose"
                        :visible="operation !== null && operation !== 'detail'"
                        :zIndex="10"
                        :title="$t(operationTitle)"
                    >
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 10 }"/>
                                </b-col>
                                <b-col cols="12" v-show="global.pendingRequests <= 0">
                                    <b-row>
                                        <b-col sm="12" class="mb-3" v-if="operation !== 'add' && has_order_draft">
                                            <span class="text-danger">*{{$t('notifications.description.orderDraftExist')}}</span>
                                        </b-col>
                                        <b-col sm="4">
                                            <b-form-group
                                                :label="$t('input.supplier')+' *'"
                                                label-for="supplier_id"
                                                :invalid-feedback="formErrors.first('supplier_id')">
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.suppliers"
                                                    placeholder=""
                                                    v-model="formFields.supplier_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('supplier_id'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="4">
                                            <b-form-group
                                                :label="$t('input.route')+' *'"
                                                label-for="route_id"
                                                :invalid-feedback="formErrors.first('route_id')">
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.routes"
                                                    placeholder=""
                                                    v-model="formFields.route_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('route_id'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="4">
                                            <b-form-group
                                                :label="$t('input.market')+' *'"
                                                label-for="market"
                                                :invalid-feedback="formErrors.first('markets')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.markets"
                                                    placeholder=""
                                                    v-model="formFields.markets"
                                                    :class="[{'invalid is-invalid': (formErrors.has('markets'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="6">
                                            <b-form-group
                                                :label="$t('input.brand')+' *'"
                                                label-for="brand_id"
                                                :invalid-feedback="formErrors.first('brand_id')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.brands"
                                                    placeholder=""
                                                    v-model="formFields.brands"
                                                    :class="[{'invalid is-invalid': (formErrors.has('brands'))}]"
                                                    @deselect="handleBrandDeSelect"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="6">
                                            <b-row>
                                                <b-col sm="6" class="mb-2">
                                                    {{$t('input.model')}} *
                                                </b-col>
                                                <b-col sm="6" class="mb-2 text-right">
                                                    <label
                                                        class="m-0 p-0">
                                                        <input
                                                            v-model="selectAllModel"
                                                            type="checkbox"
                                                            @change="handleSelectAllModels"/>
                                                        {{$t('input.selectAll')}}
                                                    </label>
                                                </b-col>
                                            </b-row>
                                            <b-form-group
                                                label-for="model_id"
                                                :depend-brand-id="formFields.brand_id"
                                                :invalid-feedback="formErrors.first('models')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="_.filter(dropdowns.models, (item) => _.includes(formFields.brands, item.brand_id))"
                                                    placeholder=""
                                                    v-model="formFields.models"
                                                    :class="[{'invalid is-invalid': (formErrors.has('models'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="6">
                                            <b-form-group
                                                :label="$t('input.logistic_type')+' *'"
                                                label-for="logistic_type_id"
                                                :invalid-feedback="formErrors.first('logistic_type_id')">
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.logistic_types"
                                                    placeholder=""
                                                    v-model="formFields.logistic_type_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('logistic_type_id'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="6">
                                            <b-form-group
                                                :label="$t('input.loadingFactors')+' *'"
                                                label-for="loading_factors"
                                                :invalid-feedback="formErrors.first('loading_factors')"
                                            >
                                                <b-form-input
                                                    id="loading_factors"
                                                    v-model="formFields.loading_factors"
                                                    type="number"
                                                    step=".2"
                                                    @blur="handleChangeCalculation"
                                                    :state="((formErrors.has('loading_factors') ? false : null))"
                                                    ref="street"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                    <b-row class="mt-3">
                                        <b-col sm="8">
                                            <b-row>
                                                <b-col sm="12">
                                                    <label class="time-label">{{$t('input.leadTimes')}}</label>
                                                </b-col>
                                                <b-col sm="6">
                                                    <b-form-group
                                                        :label="$t('input.pickup')+' *'"
                                                        label-for="lead_time_pickup"
                                                        :invalid-feedback="formErrors.first('lead_time_pickup')"
                                                    >
                                                        <b-form-input
                                                            id="lead_time_pickup"
                                                            v-model="formFields.lead_time_pickup"
                                                            type="number"
                                                            :state="((formErrors.has('lead_time_pickup') ? false : null))"
                                                            ref="lead_time_pickup"
                                                            step=".5"
                                                            @focus="$event.target.select()"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col sm="6">
                                                    <b-form-group
                                                        :label="$t('input.transit')+' *'"
                                                        label-for="lead_time_transport"
                                                        :invalid-feedback="formErrors.first('lead_time_transport')"
                                                    >
                                                        <b-form-input
                                                            id="lead_time_transport"
                                                            v-model="formFields.lead_time_transport"
                                                            type="number"
                                                            :state="((formErrors.has('lead_time_transport') ? false : null))"
                                                            step=".2"
                                                            ref="lead_time_transport"
                                                            @focus="$event.target.select()"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col sm="4">
                                            <b-form-group
                                                :label="$t('input.inboundDelay')+' *'"
                                                label-for="inbound_delay"
                                                :invalid-feedback="formErrors.first('inbound_delay')"
                                            >
                                                <b-form-input
                                                    id="inbound_delay"
                                                    v-model="formFields.inbound_delay"
                                                    type="number"
                                                    :state="((formErrors.has('inbound_delay') ? false : null))"
                                                    ref="inbound_delay"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="4">
                                            <b-form-group
                                                :label="$t('input.outboundDelay')+' *'"
                                                label-for="outbound_delay"
                                                :invalid-feedback="formErrors.first('outbound_delay')"
                                            >
                                                <b-form-input
                                                    id="outboundDelay"
                                                    v-model="formFields.outbound_delay"
                                                    type="number"
                                                    :state="((formErrors.has('outbound_delay') ? false : null))"
                                                    ref="outbound_delay"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="4">
                                            <b-form-group
                                                :label="$t('input.commitmentOnTruckLoadsPerWeek')+' *'"
                                                label-for="commitment_on_truck_loads_per_week"
                                                :invalid-feedback="formErrors.first('commitment_on_truck_loads_per_week')"
                                            >
                                                <b-form-input
                                                    id="commitment_on_truck_loads_per_week"
                                                    v-model="formFields.commitment_on_truck_loads_per_week"
                                                    type="text"
                                                    :state="((formErrors.has('commitment_on_truck_loads_per_week') ? false : null))"
                                                    ref="commitment_on_truck_loads_per_week"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="4">
                                            <b-form-group
                                                :label="$t('input.flexibility')+' *'"
                                                label-for="flexibility"
                                                :invalid-feedback="formErrors.first('flexibility')"
                                            >
                                                <b-form-input
                                                    id="flexibility"
                                                    v-model="formFields.flexibility"
                                                    type="number"
                                                    :state="((formErrors.has('flexibility') ? false : null))"
                                                    ref="flexibility"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col lg="12" md="12" sm="12">
                                            <b-form-group
                                                :label="$t('input.comment')+' '"
                                                label-for="comment"
                                                :invalid-feedback="formErrors.first('comments')"
                                            >
                                                <b-form-input
                                                    id="comment"
                                                    v-model="formFields.comments"
                                                    type="text"
                                                    :state="((formErrors.has('comments') ? false : null))"
                                                    ref="comment"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row><!--/b-row-->
                                    <b-row>
                                        <!--# Start price periods #-->
                                        <b-col lg="12" md="12" sm="12" class="mt-3">
                                            <b-card class="mb-0">
                                                <b-card-header v-b-toggle.price-periods class="p-0">
                                                    <h4 class="mb-0">
                                                <span class="badge badge-primary">
                                                    {{$t('title.pricePeriods')}}
                                                </span>
                                                        <small v-show="price_periods.length > 0">
                                                            - {{price_periods.length}} Item/s
                                                        </small>
                                                        <div class="invalid-feedback d-block"
                                                             v-if="formErrors.has('price_periods')">
                                                            <small>{{formErrors.first('price_periods')}}</small>
                                                        </div>
                                                    </h4>
                                                </b-card-header><!-- /.p-0-->

                                                <b-collapse visible id="price-periods">
                                                    <div class="bg-light p-3">
                                                        <b-row>
                                                            <b-col sm="3">
                                                                <b-form-group
                                                                    :label="$t('input.priceUnit')+' *'"
                                                                    label-for="price_unit"
                                                                    :invalid-feedback="formErrors.first('price_unit')"
                                                                >
                                                                    <b-form-input
                                                                        id="price_unit"
                                                                        step=".01"
                                                                        v-model="price_period.price_unit"
                                                                        type="number"
                                                                        :state="((formErrors.has('price_unit') ? false : null))"
                                                                        ref="price_unit"
                                                                        disabled
                                                                        @focus="$event.target.select()"
                                                                    ></b-form-input>
                                                                </b-form-group>
                                                            </b-col><!--/b-col-->
                                                            <b-col sm="3">
                                                                <b-form-group
                                                                    :label="$t('input.priceFTL')+' *'"
                                                                    label-for="price_ftl"
                                                                    :invalid-feedback="formErrors.first('price_ftl')"
                                                                >
                                                                    <b-form-input
                                                                        id="price_ftl"
                                                                        step=".01"
                                                                        v-model="price_period.price_ftl"
                                                                        @blur="handleChangeCalculation"
                                                                        type="number"
                                                                        :state="((formErrors.has('price_ftl') ? false : null))"
                                                                        ref="price_ftl"
                                                                    ></b-form-input>
                                                                </b-form-group>
                                                            </b-col><!--/b-col-->
                                                            <b-col sm="3">
                                                                <b-form-group
                                                                    :label="$t('input.priceSPL')+' *'"
                                                                    label-for="price_spl"
                                                                    :invalid-feedback="formErrors.first('price_spl')"
                                                                >
                                                                    <b-form-input
                                                                        id="price_ftl"
                                                                        step=".01"
                                                                        v-model="price_period.price_spl"
                                                                        type="number"
                                                                        :state="((formErrors.has('price_spl') ? false : null))"
                                                                        ref="price_spl"
                                                                    ></b-form-input>
                                                                </b-form-group>
                                                            </b-col><!--/b-col-->
                                                            <b-col sm="3">
                                                                <b-form-group
                                                                    :label="$t('input.currency')+' *'"
                                                                    label-for="route_id"
                                                                    :invalid-feedback="formErrors.first('currency')">
                                                                    <treeselect
                                                                        :multiple="false"
                                                                        :options="dropdowns.currencies"
                                                                        placeholder=""
                                                                        v-model="price_period.currency"
                                                                        :class="[{'invalid is-invalid': (formErrors.has('currency'))}]"
                                                                    />
                                                                </b-form-group>
                                                            </b-col><!--/b-col-->
                                                            <b-col sm="4">
                                                                <b-form-group
                                                                    :label="$t('input.startDate')+' *'"
                                                                    label-for="start_date">
                                                                    <b-form-datepicker
                                                                        placeholder=""
                                                                        id="startDate"
                                                                        v-model="price_period.start_date"
                                                                        :state="((formErrors.has('start_date') ? false : null))"
                                                                        :class="[{'invalid is-invalid': (formErrors.has('start_date'))}]"
                                                                        class="mb-2"></b-form-datepicker>
                                                                </b-form-group>
                                                            </b-col><!--/b-col-->
                                                            <b-col sm="4">
                                                                <b-form-group
                                                                    :label="$t('input.endDate')+' *'"
                                                                    label-for="end_date">
                                                                    <b-form-datepicker placeholder=""
                                                                                       id="end_date"
                                                                                       v-model="price_period.end_date"
                                                                                       :state="((formErrors.has('end_date') ? false : null))"
                                                                                       :class="[{'invalid is-invalid': (formErrors.has('end_date'))}]"
                                                                                       class="mb-2"></b-form-datepicker>
                                                                </b-form-group>
                                                            </b-col><!--/b-col-->
                                                            <b-col lg="2" md="2" sm="12">
                                                                <div class="form-group">
                                                                    <label class="d-block">
                                                                        <pre> </pre>
                                                                    </label>
                                                                    <b-button variant="info" type="button" size="sm"
                                                                              class="btn-block"
                                                                              @click="handleAddUpdatePricePeriodClick()">
                                                                        <i class="fa fa-plus"></i>
                                                                    </b-button><!--/b-button-->
                                                                </div><!-- /.form-group -->
                                                            </b-col><!--/b-col-->
                                                            <b-col lg="2" md="2" sm="12">
                                                                <div class="form-group">
                                                                    <label class="d-block">
                                                                        <pre> </pre>
                                                                    </label>
                                                                    <b-button variant="warning" type="button" size="sm"
                                                                              class="btn-block"
                                                                              @click="resetPricePeriod()">
                                                                        {{$t('button.reset')}}
                                                                    </b-button><!--/b-button-->
                                                                </div><!-- /.form-group -->
                                                            </b-col><!--/b-col-->
                                                        </b-row><!--/b-row-->
                                                        <b-row>
                                                            <b-col cols="12">
                                                                <table class="table table-bordered bg-white">
                                                                    <thead>
                                                                    <tr>
                                                                        <th width="50">#</th>
                                                                        <th width="80">{{$t('column.priceUnit')}}</th>
                                                                        <th width="80">{{$t('column.priceFTL')}}</th>
                                                                        <th width="80">{{$t('column.priceSPL')}}</th>
                                                                        <th width="80">{{$t('column.currency')}}</th>
                                                                        <th>{{$t('column.startDate')}}</th>
                                                                        <th>{{$t('column.endDate')}}</th>
                                                                        <th>{{$t('column.modifiedAt')}}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr v-for="(ct, index) in price_periods"
                                                                        :class="[{'table-primary': ct.token === price_period.token}]">
                                                                        <td>{{index + 1}}</td>
                                                                        <td>
                                                                            {{ct.price_unit}}
                                                                        </td>
                                                                        <td>
                                                                            {{ct.price_ftl}}
                                                                        </td>
                                                                        <td>
                                                                            {{ct.price_spl ? ct.price_spl : 0}}
                                                                        </td>
                                                                        <td>
                                                                            {{(_.find(dropdowns.currencies, {id:
                                                                            ct.currency}) ?
                                                                            _.find(dropdowns.currencies, {id:
                                                                            ct.currency}).label : '')}}
                                                                        </td>
                                                                        <td>
                                                                            {{$global.utcDateToLocalDate(ct.start_date,
                                                                            'DD.MM.YYYY')}}
                                                                        </td>
                                                                        <td>
                                                                            {{$global.utcDateToLocalDate(ct.end_date,
                                                                            'DD.MM.YYYY')}}
                                                                        </td>
                                                                        <td>
                                                                            {{ ct.updated_at ?
                                                                            $global.utcDateToLocalDate(ct.updated_at) :
                                                                            $global.utcDateToLocalDate(new Date())}}
                                                                        </td>
                                                                        <td>
                                                                            <a @click="handleEditPricePeriodClick(ct.token)"
                                                                               :title="$t('button.title.editItem')"
                                                                               v-b-tooltip.hover>
                                                                                <i class="fe fe-edit"></i>
                                                                            </a>
                                                                            <a-popconfirm title="Are you sure？"
                                                                                          @confirm="handleDeletePricePeriodClick(ct.token)">
                                                                                <i slot="icon" class="fe fe-trash"></i>
                                                                                <a class=" ml-1"
                                                                                   :title="$t('button.title.deleteItem')"
                                                                                   v-b-tooltip.hover>
                                                                                    <i class="fe fe-trash"></i>
                                                                                </a>
                                                                            </a-popconfirm>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                    <tfoot v-show="price_periods.length <= 0">
                                                                    <tr>
                                                                        <th colspan="7">
                                                                            {{$t('title.noDataAvailable')}}
                                                                        </th>
                                                                    </tr>
                                                                    </tfoot>
                                                                </table><!-- /.table table-bordered -->
                                                            </b-col><!--/b-col-->
                                                        </b-row><!--/b-row-->
                                                    </div><!-- /.bg-light -->
                                                </b-collapse><!-- /#price-documents-->
                                            </b-card><!-- /b-card -->
                                        </b-col><!--/b-col-->
                                        <!--# Start price documents #-->
                                        <b-col lg="12" md="12" sm="12" class="mt-3">
                                            <b-card class="mb-0">
                                                <b-card-header v-b-toggle.price-documents class="p-0">
                                                    <h4 class="mb-0">
														<span class="badge badge-primary">
															{{$t('title.priceAttachments')}}
														</span>
                                                        <small v-show="price_documents.length > 0">
                                                            - {{price_documents.length}} Item/s
                                                        </small>
                                                    </h4>
                                                </b-card-header><!-- /.p-0-->

                                                <b-collapse id="price-documents">
                                                    <div class="bg-light p-3">
                                                        <b-row>
                                                            <b-col lg="4" md="4" sm="12">
                                                                <div class="form-group">
                                                                    <b-form-group
                                                                        :label="$t('input.title')+' *'"
                                                                        label-for="title"
                                                                        :invalid-feedback="formErrors.first('title')"
                                                                    >
                                                                        <b-form-input
                                                                            id="title"
                                                                            v-model="price_document.title"
                                                                            type="text"
                                                                            :state="((formErrors.has('title') ? false : null))"
                                                                            @focus="$event.target.select()"
                                                                        ></b-form-input>
                                                                    </b-form-group>
                                                                    <div class="invalid-feedback">
                                                                        {{formErrors.first('title')}}
                                                                    </div>
                                                                </div><!-- /.form-group -->
                                                            </b-col><!--/b-col-->
                                                            <b-col lg="4" md="4" sm="12">
                                                                <b-form-group class="mt-4 pt-2">
                                                                    <upload v-model="price_document.document"
                                                                            :disabled="price_document.document"
                                                                            :title="(price_document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                                            css-class="mt-0 btn-sm"></upload>
                                                                    <b-button :title="$t('msc.removeUpload')"
                                                                              variant="outline-primary"
                                                                              type="button"
                                                                              v-b-tooltip.hover
                                                                              class="ml-2 ml-2 btn-sm"
                                                                              @click="() => {price_document.document = null; price_document.document = null}"
                                                                              :disabled="!price_document.document"
                                                                              v-if="price_document.document">
                                                                        <i class="fa fa-close"></i>
                                                                    </b-button>
                                                                    <b-button :title="$t('button.download')"
                                                                              v-b-tooltip.hover
                                                                              type="button"
                                                                              variant="outline-primary"
                                                                              class="ml-2 ml-2 btn-sm"
                                                                              v-if="price_document.document && price_document.document.download_url"
                                                                              :disabled="!(price_document.document && price_document.document.download_url)"
                                                                              :href="(price_document.document ? price_document.document.download_url : '')"
                                                                              target="_blank">
                                                                        <i class="fa fa-cloud-download"></i>
                                                                    </b-button>
                                                                </b-form-group>
                                                                <div class="invalid-feedback d-block">
                                                                    {{formErrors.first('document')}}
                                                                </div>
                                                            </b-col><!--/b-col-->
                                                            <b-col lg="2" md="2" sm="12">
                                                                <div class="form-group">
                                                                    <label class="d-block">
                                                                        <pre> </pre>
                                                                    </label>
                                                                    <b-button variant="info" type="button" size="sm"
                                                                              class="btn-block"
                                                                              @click="handleAddUpdatePriceDocumentClick()">
                                                                        <i class="fa fa-plus"></i>
                                                                    </b-button><!--/b-button-->
                                                                </div><!-- /.form-group -->
                                                            </b-col><!--/b-col-->
                                                            <b-col lg="2" md="2" sm="12">
                                                                <div class="form-group">
                                                                    <label class="d-block">
                                                                        <pre> </pre>
                                                                    </label>
                                                                    <b-button variant="warning" type="button" size="sm"
                                                                              class="btn-block"
                                                                              @click="resetPriceDocument()">
                                                                        {{$t('button.reset')}}
                                                                    </b-button><!--/b-button-->
                                                                </div><!-- /.form-group -->
                                                            </b-col><!--/b-col-->
                                                        </b-row><!--/b-row-->
                                                        <b-row>
                                                            <b-col cols="12">
                                                                <table class="table table-bordered bg-white">
                                                                    <thead>
                                                                    <tr>
                                                                        <th width="50">#</th>
                                                                        <th width="180">{{$t('column.title')}}</th>
                                                                        <th width="60">{{$t('column.action')}}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr v-for="(ct, index) in price_documents"
                                                                        :class="[{'table-primary': ct.token === price_document.token}]">
                                                                        <td>{{index + 1}}</td>
                                                                        <td>
                                                                            {{ct.title}}
                                                                        </td>
                                                                        <td>
                                                                            <a @click="handleEditPriceDocumentClick(ct.token)"
                                                                               :title="$t('button.title.editItem')"
                                                                               v-b-tooltip.hover>
                                                                                <i class="fe fe-edit"></i>
                                                                            </a>
                                                                            <a :title="$t('button.download')"
                                                                               class=" ml-1"
                                                                               :href="ct.document.download_url"
                                                                               target="_blank" v-b-tooltip.hover>
                                                                                <i class="fa fa-cloud-download"></i>
                                                                            </a>
                                                                            <a-popconfirm title="Are you sure？"
                                                                                          @confirm="handleDeletePriceDocumentClick(ct.token)">
                                                                                <i slot="icon" class="fe fe-trash"></i>
                                                                                <a class=" ml-1"
                                                                                   :title="$t('button.title.deleteItem')"
                                                                                   v-b-tooltip.hover>
                                                                                    <i class="fe fe-trash"></i>
                                                                                </a>
                                                                            </a-popconfirm>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                    <tfoot v-show="price_documents.length <= 0">
                                                                    <tr>
                                                                        <th colspan="5">
                                                                            {{$t('title.noDataAvailable')}}
                                                                        </th>
                                                                    </tr>
                                                                    </tfoot>
                                                                </table><!-- /.table table-bordered -->
                                                            </b-col><!--/b-col-->
                                                        </b-row><!--/b-row-->
                                                    </div><!-- /.bg-light -->
                                                </b-collapse><!-- /#price-documents-->
                                            </b-card><!-- /b-card -->
                                        </b-col><!--/b-col-->
                                    </b-row>

                                    <b-row>
                                        <!--# Start price model exceptions #-->
                                        <b-col lg="12" md="12" sm="12" class="mt-3 mb-5">
                                            <b-card class="mb-0">
                                                <b-card-header v-b-toggle.price-model-exception class="p-0">
                                                    <h4 class="mb-0">
                                                        <span class="badge badge-primary">
                                                            {{$t('title.priceModelExceptions')}}
                                                        </span>
                                                        <small v-show="price_model_exceptions.length > 0">
                                                            - {{price_model_exceptions.length}} Item/s
                                                        </small>
                                                        <div class="invalid-feedback d-block"
                                                             v-if="formErrors.has('price_model_exceptions')">
                                                            <small>{{formErrors.first('price_model_exceptions')}}</small>
                                                        </div>
                                                    </h4>
                                                </b-card-header><!-- /.p-0-->

                                                <b-collapse visible id="price-model-exceptions">
                                                    <div class="bg-light p-3">
                                                        <b-row>
                                                            <b-col sm="4">
                                                                <b-form-group
                                                                    :label="$t('input.models')+' *'"
                                                                    label-for="model_id"
                                                                    :invalid-feedback="formErrors.first('pme_model_id')">
                                                                    <treeselect
                                                                        :multiple="true"
                                                                        :options="_.filter(dropdowns.models, (item) => _.includes(formFields.models, item.id))"
                                                                        placeholder=""
                                                                        v-model="price_model_exception.model_id"
                                                                        :class="[{'invalid is-invalid': (formErrors.has('pme_model_id'))}]"></treeselect>
                                                                </b-form-group>
                                                            </b-col><!--/b-col-->
                                                            <b-col sm="4">
                                                                <b-form-group
                                                                    :label="$t('input.price')+' *'"
                                                                    label-for="price"
                                                                    :invalid-feedback="formErrors.first('pme_price')"
                                                                >
                                                                    <b-form-input
                                                                        id="price"
                                                                        v-model="price_model_exception.price"
                                                                        type="number"
                                                                        :state="((formErrors.has('pme_price') ? false : null))"
                                                                        ref="price"
                                                                        @focus="$event.target.select()"
                                                                    ></b-form-input>
                                                                </b-form-group>
                                                            </b-col><!--/b-col-->
                                                            <b-col sm="4">
                                                                <b-form-group
                                                                    :label="$t('input.currency')+' *'"
                                                                    label-for="currency"
                                                                    :invalid-feedback="formErrors.first('pme_currency')">
                                                                    <treeselect
                                                                        :multiple="false"
                                                                        :options="dropdowns.currencies"
                                                                        placeholder=""
                                                                        v-model="price_model_exception.currency"
                                                                        :class="[{'invalid is-invalid': (formErrors.has('pme_currency'))}]"
                                                                    />
                                                                </b-form-group>
                                                            </b-col><!--/b-col-->
                                                            <b-col lg="2" md="2" sm="12">
                                                                <div class="form-group">
                                                                    <b-button variant="info" type="button" size="sm"
                                                                              class="btn-block"
                                                                              @click="handleAddUpdatePriceModelExceptionClick()">
                                                                        <i class="fa fa-plus"></i>
                                                                    </b-button><!--/b-button-->
                                                                </div><!-- /.form-group -->
                                                            </b-col><!--/b-col-->
                                                            <b-col lg="2" md="2" sm="12">
                                                                <div class="form-group">
                                                                    <b-button variant="warning" type="button" size="sm"
                                                                              class="btn-block"
                                                                              @click="resetPriceModelException()">
                                                                        {{$t('button.reset')}}
                                                                    </b-button><!--/b-button-->
                                                                </div><!-- /.form-group -->
                                                            </b-col><!--/b-col-->
                                                        </b-row><!--/b-row-->
                                                        <b-row>
                                                            <b-col cols="12">
                                                                <table class="table table-bordered bg-white">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="50">#</th>
                                                                            <th>{{$t('column.model')}}</th>
                                                                            <th>{{$t('column.price')}}</th>
                                                                            <th>{{$t('column.currency')}}</th>
                                                                            <th width="100">{{$t('column.action')}}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr v-for="(ct, index) in price_model_exceptions"
                                                                        :class="[{'table-primary': ct.token === price_model_exception.token}]">
                                                                        <td>{{index + 1}}</td>
                                                                        <td>
                                                                            {{(_.find(dropdowns.models, {id:ct.model_id}) || {}).label}}
                                                                        </td>
                                                                        <td>{{ct.price}}</td>
                                                                        <td>
                                                                            {{(_.find(dropdowns.currencies, {id:
                                                                            ct.currency}) ? _.find(dropdowns.currencies,
                                                                            {id: ct.currency}).label : '')}}
                                                                        </td>
                                                                        <td>
<!--                                                                            <a @click="handleEditPriceModelExceptionClick(ct.token)"-->
<!--                                                                               :title="$t('button.title.editItem')"-->
<!--                                                                               v-b-tooltip.hover>-->
<!--                                                                                <i class="fe fe-edit"></i>-->
<!--                                                                            </a>-->
                                                                            <a-popconfirm title="Are you sure？"
                                                                                          @confirm="handleDeletePriceModelExceptionClick(ct.token)">
                                                                                <i slot="icon" class="fe fe-trash"></i>
                                                                                <a class=" ml-1"
                                                                                   :title="$t('button.title.deleteItem')"
                                                                                   v-b-tooltip.hover>
                                                                                    <i class="fe fe-trash"></i>
                                                                                </a>
                                                                            </a-popconfirm>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                    <tfoot v-show="price_model_exceptions.length <= 0">
                                                                    <tr>
                                                                        <th colspan="7">
                                                                            {{$t('title.noDataAvailable')}}
                                                                        </th>
                                                                    </tr>
                                                                    </tfoot>
                                                                </table><!-- /.table table-bordered -->
                                                            </b-col><!--/b-col-->
                                                        </b-row><!--/b-row-->
                                                        <span>{{$t('msc.systemWillOnlyStoreTheModelThatAreSelectWithPrice')}}</span>
                                                    </div><!-- /.bg-light -->
                                                </b-collapse><!-- /#price-documents-->
                                            </b-card><!-- /b-card -->
                                        </b-col><!--/b-col-->
                                    </b-row>
                                </b-col><!--/b-col-->
                                <div class="drawer-footer">
                                    <b-button
                                        size="sm"
                                        type="submit"
                                        variant="primary"
                                        :disabled="global.pendingRequests > 0"
                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                    >
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        <i class="fa fa-save mr-1"></i>
                                        {{$t('button.save')}}
                                    </b-button>
                                    <b-button variant="warning" class="ml-3"
                                              size="sm" @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </b-row><!--/b-row-->
                        </form><!--/form-->
                    </a-drawer>
                </div><!--/.prices-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.supplier')+' *'">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.suppliers"
                                            placeholder=""
                                            v-model="filters.suppliers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.route')+' *'">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.routes"
                                            placeholder=""
                                            v-model="filters.routes"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.brand')+' *'">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.brands"
                                            placeholder=""
                                            v-model="filters.brands"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.model')+' *'">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.models"
                                            placeholder=""
                                            v-model="filters.models"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.logistic_type')+' *'">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.logistic_types"
                                            placeholder=""
                                            v-model="filters.logistic_types"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker reset-button placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12" class="mb-3">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker reset-button placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>
    </div>
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import PriceDocumentMixin from "./PriceDocumentMixin"
    import PricePeriodMixing from "./PricePeriodMixing"
    import moment from 'moment-timezone'
    import Detail from "./detail"
    import PriceModelException from "./PriceModelException";

    const FORM_STATE = {
        supplier_id: null,
        route_id: null,
        brands: [],
        models: [],
        markets: [],
        logistic_type_id: null,
        loading_factors: 0,
        comments: null,
        price_documents: [],
        price_periods: [],
        price_model_exceptions: [],
        lead_time_pickup: 0,
        lead_time_transport: 0,
        flexibility: 0,
        commitment_on_truck_loads_per_week: 0,
        outbound_delay: 0,
        inbound_delay: 0,
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        suppliers: [],
        routes: [],
        logistic_types: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: '#',
            key: 'id',
            sortable: false,
        },
        {
            label: self.$t('column.supplier'),
            key: 'supplier_id',
            sortable: true,
        },
        {
            label: self.$t('column.route'),
            key: 'route_id',
            sortable: true,
        },
        {
            label: self.$t('column.brands'),
            key: 'brands',
            sortable: true,
        },
        {
            label: self.$t('column.models'),
            key: 'models',
            sortable: true,
        },
        {
            label: self.$t('column.leadTimePickup'),
            key: 'lead_time_pickup',
            sortable: true,
        },
        {
            label: self.$t('column.leadTimeTransport'),
            key: 'lead_time_transport',
            sortable: true,
        },
        {
            label: self.$t('column.loadingFactors'),
            key: 'loading_factors',
            sortable: true,
        },
        {
            label: self.$t('column.priceUnit'),
            key: 'price_unit',
            sortable: false,
        },
        {
            label: self.$t('column.priceFTL'),
            key: 'price_ftl',
            sortable: false,
        },
        {
            label: self.$t('column.priceSPL'),
            key: 'price_spl',
            sortable: false,
        },
        (self.$global.hasAnyPermission(['pricesupdate', 'pricesdestroy'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, PriceDocumentMixin, PricePeriodMixing, PriceModelException],
        components: {
            Treeselect,
            Detail,
        },
        data() {
            return {
                operationTitle: 'title.prices',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'prices',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    suppliers: [],
                    routes: [],
                    brands: [],
                    models: [],
                    markets: [],
                    logistic_types: [],
                    currencies: []
                },
                show: true,
                has_order_draft: false,
                selectAllModel: false,
            }
        },
        mounted() {
            this.getLogisticTypes();
            this.getSuppliersByType();
            this.getRoutes();
            this.getBrands();
            this.getModels();
            this.getCurrencies();
            this.getCountries();

            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('topBar.navigations.modules.prices')
        },
        methods: {
            handlePricePeriodShow(price) {
                return moment(price.start_date) <= moment().toDate() && moment().toDate() <= moment(price.end_date)
            },
            doesPricePeriodExpired(periods) {
                const hasAny = periods.filter((price) => (moment(price.start_date) <= moment().toDate() && moment().toDate() <= moment(price.end_date)))
                return hasAny.length;
            },
            doesPricePeriodExpireInOneMonth(periods) {
                const hasAny = periods.filter((price) => ((moment(price.start_date) <= moment().toDate() && moment().toDate() <= moment(price.end_date))
                    && (moment(price.end_date).diff(moment().toDate(), 'days') >= 0 && moment(price.end_date).diff(moment().toDate(), 'days') <= 30)))
                return hasAny.length;
            },
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addPrice' : 'title.editPrice')
                this.resetPriceDocument()
                this.resetPricePeriod()
                this.price_documents.length = 0
                this.price_periods.length = 0
                this.price_model_exceptions.length = 0
                this.selectAllModel = false
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: this.formFields.id ? 'prices/update' : 'prices/create',
                        method: 'post',
                        data: {
                            ...this.formFields,
                            price_documents: this.price_documents,
                            price_periods: this.price_periods,
                            price_model_exceptions: this.price_model_exceptions
                        },
                    })

                    if (this.formFields.id) {
                        if (response.success == 'order_draft_exist') {
                            this.orderDraftExist()
                        } else {
                            this.itemUpdated()
                        }
                    } else {
                        this.itemAdded()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/prices/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (errors) {
                    this.itemDeleteFails()
                }
            },
            async handleEditClick(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/prices/detail/${id}`,
                    })
                    this.operationTitle = this.$t('title.editPrice')
                    const {data} = response
                    const {supplier, route, brands, models, logistic_type, price_documents, price_periods, markets, price_model_exceptions} = data
                    delete data.supplier
                    delete data.route
                    delete data.brands
                    delete data.models
                    delete data.logistic_type
                    delete data.price_documents
                    delete data.price_periods
                    delete data.markets
                    delete data.price_model_exceptions

                    this.formFields = {
                        ...data,
                        supplier_id: (supplier ? supplier.id : null),
                        route_id: (route ? route.id : null),
                        models: (models ? _.map(models, (i) => i.id) : []),
                        brands: (brands ? _.map(brands, (i) => i.id) : []),
                        markets: (markets ? _.map(markets, (i) => i.name) : []),
                        logistic_type_id: (logistic_type ? logistic_type.id : null),
                        lead_time_pickup: (data.lead_time_pickup ? data.lead_time_pickup : 0),
                        lead_time_transport: (data.lead_time_transport ? data.lead_time_transport : 0),
                        flexibility: (data.flexibility ? data.flexibility : 0),
                        commitment_on_truck_loads_per_week: (data.commitment_on_truck_loads_per_week ? data.commitment_on_truck_loads_per_week : 0),
                        outbound_delay: (data.outbound_delay ? data.outbound_delay : 0),
                        inbound_delay: (data.outbound_delay ? data.inbound_delay : 0),
                    }

                    this.price_documents = price_documents.map((item) => {
                        return {
                            id: item.id,
                            token: item.token,
                            document: item.document,
                            title: item.title
                        }
                    })

                    this.price_periods = price_periods.map((item) => {
                        return {
                            id: item.id,
                            token: item.token,
                            price_unit: item.price_unit,
                            price_ftl: item.price_ftl,
                            price_spl: item.price_spl,
                            currency: item.currency,
                            start_date: item.start_date,
                            end_date: item.end_date,
                            updated_at: item.updated_at,
                        }
                    })

                    this.price_model_exceptions = price_model_exceptions.map((item) => {
                        return {
                            id: item.id,
                            token: item.token,
                            currency: item.currency,
                            model_id: item.model ? item.model.id : [],
                            price: item.price,
                        }
                    })
                    this.has_order_draft = data.has_order_draft;
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                }
            },
            async getSuppliersByType() {
                try {
                    const slug = 'carrier';
                    const response = await request({
                        url: `/dropdowns/suppliers/${slug}`,
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.suppliers = data

                } catch (e) {
                    this.dropdowns.suppliers = []
                }
            },
            async getRoutes() {
                try {
                    const response = await request({
                        url: '/dropdowns/routes',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.routes = data
                } catch (e) {
                    this.dropdowns.routes = []
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = _.map(data, (item) => ({
                        id: item.id,
                        label: `${item.label}-${item.version_code}`,
                        brand_id: item.brand_id
                    }))
                } catch (e) {
                    this.dropdowns.models = []
                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            async getLogisticTypes() {
                try {
                    const response = await request({
                        url: '/dropdowns/logistic/types',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.logistic_types = data

                } catch (e) {
                    this.dropdowns.logistic_types = []
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.markets = data

                } catch (e) {
                    this.dropdowns.markets = []
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('pricesview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
            handleBrandDeSelect(event) {
                const modelToBeRemoved = _.filter(this.formFields.models, (item) => !_.includes(_.filter(this.dropdowns.models, i => i.brand_id === event.id).map(i => i.id), item))
                this.formFields.models = modelToBeRemoved
            },
            handleChangeCalculation() {
                this.price_period.price_unit = 0;
                this.price_period.price_ftl = (this.price_period.price_ftl && this.price_period.price_ftl > 0) ? parseFloat(this.price_period.price_ftl) : 0;
                this.formFields.loading_factors = (this.formFields.loading_factors && this.formFields.loading_factors > 0) ? parseFloat(this.formFields.loading_factors) : 0;
                if (this.formFields.loading_factors > 0 && this.price_period.price_ftl > 0) {
                    this.price_period.price_unit = parseFloat(this.price_period.price_ftl / this.formFields.loading_factors).toFixed(2);
                }
            },
            handleSelectAllModels(e) {
                this.formFields.models = []

                this.$nextTick(() => {
                    if (e.target.checked) {
                        this.formFields.models = _.map(_.filter(this.dropdowns.models, item => _.includes(this.formFields.brands, item.brand_id)), (i) => i.id)
                    }
                })
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .min-24px {
        min-height: 20px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }

        .min-24px {
            min-height: 24px !important;
        }
    }

    .time-label {
        margin: 0;
        font-size: 10px;
        position: absolute;
        top: -16px;
    }
</style>
