import Error from '../../util/Error'
import moment from 'moment-timezone'


const PRICE_PERIOD_STATE = {
    token: null,
    id: null,
    price_unit: 0,
    price_ftl: 0,
    price_spl: 0,
    currency: null,
    start_date: null,
    end_date: null,
    error: false,
    updated_at: null,
};

export default {
    data() {
        return {
            price_period: {...PRICE_PERIOD_STATE},
            price_periods: [],
        }
    },
    methods: {
        handleAddUpdatePricePeriodClick() {
            this.price_period.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (this.price_period.price_spl === '' || this.price_period.price_spl == null) {
                this.price_period.error = true;
                errors.price_spl = [this.$t('validation.required')];
            }

            if ((this.price_period.price_spl <= 0 || this.price_period.price_spl == null || this.price_period.price_spl == undefined) && !this.price_period.price_ftl) {
                this.price_period.error = true;
                errors.price_ftl = [this.$t('validation.required')];
            }

            if ((this.price_period.price_spl <= 0 || this.price_period.price_spl == null || this.price_period.price_spl == undefined) && !this.price_period.price_unit) {
                this.price_period.error = true;
                errors.price_unit = [this.$t('validation.required')];
            }

            if (!this.price_period.currency) {
                this.price_period.error = true;
                errors.currency = [this.$t('validation.required')];
            }

            _.map(this.price_periods, (period) => {
                if (period.token !== this.price_period.token) {
                    if (new Date(period.end_date) >= new Date(this.price_period.start_date)) {
                        errors.start_date = [this.$t('validation.duplicate')];
                        this.price_period.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if (this.price_period.error) return false;

            this.price_period.updated_at = moment().toDate()
            const entity = this.price_period;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.price_periods, {token: entity.token});

            if (this.price_periods[index] !== undefined) {
                this.price_periods[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                this.price_periods.push(entity)
            }

            this.price_period = {
                ...PRICE_PERIOD_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleDeletePricePeriodClick(token) {
            const index = _.findIndex(this.price_periods, {token: token})
            if (this.price_periods[index] !== undefined) {
                this.price_periods.splice(index, 1);
            }
        },
        handleEditPricePeriodClick(token) {
            const index = _.findIndex(this.price_periods, {token: token})
            if (this.price_periods[index] !== undefined) {
                this.price_period = {...this.price_periods[index]};
                this.price_period.price_spl = (this.price_period.price_spl ? this.price_period.price_spl : 0);
            }
        },
        resetPricePeriod() {
            this.price_period = {...PRICE_PERIOD_STATE}
            this.price_period.error = false;
        },
    }
}
